import React from "react";
import Layout from "../../components/Layout";

export default function educacionContinua() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_educativo/5.jpg)`}}
                    >
                        <h1>Educación Continua</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Propuestas educativas para solventar necesidades específicas de la comunidad. Acceda a una
                            amplia oferta desarrollada por expertos de la materia y que abarca diversas temáticas de
                            carácter estratégico:
                        </p>
                        <ul style={{color: '#000000'}}>
                            <li>Desarrollo de habilidades informacionales y alfabetización digital</li>
                            <li>Violencia política y género</li>
                            <li>Actualización docente</li>
                        </ul>
                        <p className="services_section_1__paragraph">
                            Conozca detalles sobre nuestra oferta educativa en formato de seminarios, cursos y
                            diplomados con modalidad online, ya sea por medios sincrónicos o asincrónicos:
                        </p>
                        <ul style={{color: '#000000'}}>
                            <li>Seminario de Actualización</li>
                            <li>Diplomados Online (LMS)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
}